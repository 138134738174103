import React from "react";
import "../styles/NewsSection.css";

const NewsSection = () => {
  return (
    <div className="news-section">
      <h2>🔥 News</h2>
      <div className="news-list">
        <p>
          <b>March 2025: </b>Happy to start my PhD at the Ommer-Lab.
        </p>
        <p>
          <b>February 2025: </b>Two papers accepted at CVPR 2025.
        </p>
        <p>
          <b>October 2024: </b>First author paper accepted at WACV 2025.
        </p>
        <p>
          <b>July 2024: </b>Started my internship at the Ommer-Lab.
        </p>
      </div>
    </div>
  );
};

export default NewsSection;
