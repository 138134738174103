import React from "react";
import "../styles/HeroSection.css";

const HeroSection = () => {
  const downloadCV = () => {
    const link = document.createElement("a");
    link.href = "CV.pdf";
    link.download = "Frank_Fundel_CV.pdf";
    link.click();
  };

  return (
    <div className="hero-section">
      <div className="hero-text">
        <h1>Frank Fundel</h1>
        <p>
          I am currently working as a PhD student in the{" "}
          <b>Computer Vision & Learning group</b> at LMU Munich (
          <a href="https://ommer-lab.com/" target="_blank" rel="noreferrer">
            Ommer-Lab
          </a>
          ). My research focuses on internal representations of large{" "}
          <b>diffusion models</b>. I am also passionate about applying my skills
          to real-world problems like
          <b> bioacoustics</b> and <b>conservation</b>. When I am not in front
          of the computer, I enjoy being outdoors, running, hiking, and scuba
          diving.
        </p>
        <ul className="social-links">
          <li>
            <a
              href="https://github.com/FrankFundel"
              target="_blank"
              rel="noreferrer"
            >
              GitHub
            </a>
          </li>
          <li>/</li>
          <li>
            <a
              href="https://www.linkedin.com/in/frank-fundel-804ba61b3/"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
            </a>
          </li>
          <li>/</li>
          <li>
            <a href="https://x.com/frnkfndl" target="_blank" rel="noreferrer">
              Twitter
            </a>
          </li>
          <li>/</li>
          <li>
            <a
              href="https://scholar.google.de/citations?user=1_049o0AAAAJ&hl=de"
              target="_blank"
              rel="noreferrer"
            >
              Google Scholar
            </a>
          </li>
        </ul>
      </div>
      <div className="hero-profile">
        <img src="profile.png" alt="Frank Fundel" className="profile-image" />
        <button className="download-cv" onClick={downloadCV}>
          Download CV
        </button>
      </div>
    </div>
  );
};

export default HeroSection;
